import React from "react";
import { Helmet } from "react-helmet";
import UploadStep from "./components/UploadStep";
import PrintSizeStep from "./components/PrintSizeStep";
import PaperSizeStep from "./components/PaperSizeStep";
import FinalStep from "./components/FinalStep";
import { AppProvider, useAppContext } from "./context/AppContext";
import VerticalNavBar from "./components/VerticalNavBar";
import splitPDF from "./utils/splitPDF";
import { Link } from "react-router-dom";

const AppContent = () => {
  const {
    step,
    error,
    file,
    originalFileName,
    printSize,
    fixedSize,
    unit,
    setUnit,
    totalPages,
    splitMethod,
    grid,
    bleedSize,
    showCropMarks,
    showPageNumbers,
    setIsProcessing,
    setLoading,
    setError,
    setProgress,
    cancelRef,
  } = useAppContext();

  const steps = [
    { title: "Upload", component: UploadStep },
    { title: "Completed Size", component: PrintSizeStep },
    { title: "Paper Size", component: PaperSizeStep },
    { title: "Download", component: FinalStep },
  ];

  const getStepSummary = (stepNumber) => {
    const formatDimension = (value) => {
      const formatNumber = (num) => {
        return parseFloat(num.toFixed(2)).toString();
      };

      if (unit === "inch") {
        return `${formatNumber(value)}"`;
      } else {
        return `${formatNumber(value * 2.54)}cm`;
      }
    };

    switch (stepNumber) {
      case 1:
        return file ? `${file.name}` : "";
      case 2:
        return printSize.width && printSize.height
          ? `${formatDimension(printSize.width)} x ${formatDimension(
              printSize.height
            )}`
          : "";
      case 3:
        return fixedSize.width && fixedSize.height
          ? `${formatDimension(fixedSize.width)} x ${formatDimension(
              fixedSize.height
            )}, ${totalPages}pg`
          : "";
      default:
        return "";
    }
  };

  const handleDownload = async () => {
    if (!file) {
      setError("No file selected. Please upload a PDF file first.");
      return;
    }

    setLoading(true);
    setIsProcessing(true);
    setError("");
    setProgress(0);
    cancelRef.current = false;

    try {
      const blob = await splitPDF({
        file,
        splitMethod,
        fixedSize,
        grid,
        printSize,
        bleedSize,
        showCropMarks,
        showPageNumbers,
        unit,
        setProgress,
        cancelRef,
      });

      if (!cancelRef.current) {
        const numPieces =
          splitMethod === "equalGrid"
            ? grid.reduce((sum, row) => sum + row.length, 0)
            : Math.ceil(printSize.width / (fixedSize.width - 2 * bleedSize)) *
              Math.ceil(printSize.height / (fixedSize.height - 2 * bleedSize));
        const newFileName = `${originalFileName}-PDF-Gridder-${numPieces}-pages.pdf`;

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = newFileName;
        link.click();
      }
    } catch (error) {
      if (error.message !== "Job cancelled") {
        console.error("Error splitting PDF:", error);
        setError(`An error occurred while splitting the PDF: ${error.message}`);
      }
    } finally {
      setLoading(false);
      setIsProcessing(false);
      setProgress(0);
    }
  };

  const CurrentStep = steps[step - 1].component;

  return (
    <>
      <Helmet>
        <title>PDF Gridder - Print Your PDF in Pieces</title>
        <meta
          name="description"
          content="Free online tool to split PDF files into multiple pages or custom grids. Great for large prints. Upload your PDF and split it with ease."
        />
        <meta
          name="keywords"
          content="PDF splitter, split PDF, PDF tool, online PDF splitter, PDF gridder, PDF grid, PDF print, PDF print in pieces"
        />
        <meta name="author" content="PDFGridder.com" />
        <meta
          property="og:title"
          content="PDF Gridder - Print Your Huge PDF in Pieces"
        />
        <meta
          property="og:description"
          content="Free online tool to split PDF files into multiple pages or custom grids for printing in parts."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.pdfgridder.com" />
      </Helmet>

      <div className="min-h-screen bg-gray-100 py-8 pb-20 md:pb-8">
        <div className="container mx-auto px-4 max-w-3xl lg:max-w-5xl xl:max-w-6xl">
          <header className="text-center mb-8 relative">
            <div className="flex items-center justify-center mb-2">
              <img
                src="/logo.png"
                alt="PDF Gridder Logo"
                className="h-12 w-12 mr-4"
              />
              <h1 className="text-3xl md:text-4xl font-bold text-gray-800">
                PDF Gridder
              </h1>
            </div>
            <Link to="/about" className="text-sm text-blue-600 hover:underline">
              About PDF Gridder
            </Link>
            <div className="absolute md:top-0 md:right-0 right-2 top-16 flex items-center space-x-2 md:space-x-4">
              <button
                className={`px-2 py-1 md:px-4 md:py-2 text-sm md:text-base rounded-md ${
                  unit === "inch"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
                onClick={() => setUnit("inch")}
              >
                in
              </button>
              <button
                className={`px-2 py-1 md:px-4 md:py-2 text-sm md:text-base rounded-md ${
                  unit === "cm"
                    ? "bg-blue-500 text-white"
                    : "bg-gray-200 text-gray-700"
                }`}
                onClick={() => setUnit("cm")}
              >
                cm
              </button>
            </div>
          </header>

          <div className="bg-white shadow-lg rounded-lg overflow-hidden mb-8">
            <div className="p-4 md:h-[calc(100vh-300px)] min-h-[500px] max-h-[800px] overflow-y-auto">
              <CurrentStep />
            </div>
          </div>

          {error && <p className="text-red-500 mt-4 text-center">{error}</p>}
        </div>

        <div className="hidden md:block container mx-auto px-4 max-w-3xl lg:max-w-5xl xl:max-w-6xl">
          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <VerticalNavBar
              steps={steps}
              getStepSummary={getStepSummary}
              handleDownload={handleDownload}
            />
          </div>
        </div>
      </div>

      <div className="md:hidden fixed bottom-0 left-0 right-0">
        <VerticalNavBar
          steps={steps}
          getStepSummary={getStepSummary}
          handleDownload={handleDownload}
        />
      </div>
    </>
  );
};

const App = () => (
  <AppProvider>
    <AppContent />
  </AppProvider>
);

export default App;
