import React from "react";
import { PDFDocument } from "pdf-lib";
import DragDropUpload from "./DragDropUpload";
import { useAppContext } from "../context/AppContext";

const UploadStep = () => {
  const {
    file,
    setFile,
    setOriginalFileName,
    setPdfSize,
    setPrintSize,
    setError,
    setStep,
    pdfSize,
    unit,
  } = useAppContext();

  const handleFileChange = async (file) => {
    setFile(file);
    if (file) {
      setOriginalFileName(file.name.replace(/\.pdf$/i, ""));
      try {
        const arrayBuffer = await file.arrayBuffer();
        const pdfDoc = await PDFDocument.load(arrayBuffer);
        const page = pdfDoc.getPages()[0];
        const { width, height } = page.getSize();
        const sizeInInches = {
          width: Number((width / 72).toFixed(2)),
          height: Number((height / 72).toFixed(2)),
        };
        setPdfSize(sizeInInches);
        setPrintSize(sizeInInches);
        setStep(2);
      } catch (error) {
        console.error("Error loading PDF:", error);
        setError("Error loading PDF. Please try a different file.");
      }
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
    setOriginalFileName("");
    setPdfSize({ width: 0, height: 0 });
    setPrintSize({ width: 0, height: 0 });
  };

  const formatDimension = (value) => {
    if (unit === "inch") {
      return `${value.toFixed(2)}"`;
    } else {
      return `${(value * 2.54).toFixed(2)} cm`;
    }
  };

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-2xl font-semibold mb-6 text-gray-800">
        {file ? "PDF Uploaded Successfully" : "Upload Your PDF"}
      </h2>

      <div className="flex-grow flex flex-col justify-center">
        {file ? (
          <div className="mb-8">
            <div className="bg-blue-50 rounded-lg overflow-hidden">
              <div className="p-4 flex items-center">
                <svg
                  className="w-8 h-8 text-blue-500 mr-3 flex-shrink-0"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
                </svg>
                <div className="min-w-0 flex-grow">
                  <p className="font-semibold text-blue-800 truncate">
                    {file.name}
                  </p>
                  <p className="text-sm text-blue-600">
                    {(file.size / 1024 / 1024).toFixed(2)} MB
                    {pdfSize.width && pdfSize.height && (
                      <span className="ml-2">
                        • {formatDimension(pdfSize.width)} x{" "}
                        {formatDimension(pdfSize.height)}
                      </span>
                    )}
                  </p>
                </div>
              </div>
              <div className="bg-blue-100 px-4 py-3 flex justify-between items-center">
                <button
                  onClick={handleRemoveFile}
                  className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out"
                >
                  Replace PDF
                </button>
              </div>
            </div>
          </div>
        ) : (
          <DragDropUpload onFileUpload={handleFileChange} existingFile={file} />
        )}
      </div>

      <div className="mt-8 p-4 bg-blue-50 rounded-lg">
        <h3 className="text-lg font-semibold mb-2 text-blue-800">
          About PDF Gridder
        </h3>
        <p className="text-sm text-blue-700 leading-relaxed">
          PDF Gridder is a free online tool that allows you to split large PDF
          files into smaller, printable pieces. Perfect for creating posters,
          banners, or any oversized prints. Simply upload your PDF, customize
          your grid or piece size, and download your split PDF ready for
          printing and assembly. Whether you're working on a DIY project or need
          to print a large-scale document, PDF Gridder makes it easy to achieve
          professional results with standard printers.
        </p>
      </div>
    </div>
  );
};

export default UploadStep;
