import React, { useState, useEffect, useCallback } from "react";
import { useAppContext } from "../context/AppContext";

const PrintSizeStep = () => {
  const {
    pdfSize,
    printSize,
    setPrintSize,
    scaleFactor,
    setScaleFactor,
    unit,
  } = useAppContext();

  const [width, setWidth] = useState(printSize.width);
  const [height, setHeight] = useState(printSize.height);

  const roundToTwoDecimals = (num) => Math.round(num * 100) / 100;

  const inchToCm = (inch) => inch * 2.54;
  const cmToInch = (cm) => cm / 2.54;

  const convertToCurrentUnit = useCallback(
    (valueInInches) => {
      return unit === "cm" ? inchToCm(valueInInches) : valueInInches;
    },
    [unit]
  );

  const convertToInches = useCallback(
    (value) => {
      return unit === "cm" ? cmToInch(value) : value;
    },
    [unit]
  );

  useEffect(() => {
    const newWidth = roundToTwoDecimals(convertToCurrentUnit(printSize.width));
    const newHeight = roundToTwoDecimals(
      convertToCurrentUnit(printSize.height)
    );
    setWidth(newWidth);
    setHeight(newHeight);
  }, [printSize, unit, convertToCurrentUnit]);

  const updateDimensions = (newWidth, newHeight, newScaleFactor) => {
    setWidth(roundToTwoDecimals(newWidth));
    setHeight(roundToTwoDecimals(newHeight));
    setScaleFactor(roundToTwoDecimals(newScaleFactor));
    setPrintSize({
      width: roundToTwoDecimals(convertToInches(newWidth)),
      height: roundToTwoDecimals(convertToInches(newHeight)),
    });
  };

  const handleWidthChange = (newWidth) => {
    newWidth = parseFloat(newWidth);
    if (isNaN(newWidth)) return;
    const newScaleFactor = convertToInches(newWidth) / pdfSize.width;
    const newHeight = convertToCurrentUnit(pdfSize.height * newScaleFactor);
    updateDimensions(newWidth, newHeight, newScaleFactor);
  };

  const handleHeightChange = (newHeight) => {
    newHeight = parseFloat(newHeight);
    if (isNaN(newHeight)) return;
    const newScaleFactor = convertToInches(newHeight) / pdfSize.height;
    const newWidth = convertToCurrentUnit(pdfSize.width * newScaleFactor);
    updateDimensions(newWidth, newHeight, newScaleFactor);
  };

  const handleScaleFactorChange = (newScaleFactor) => {
    newScaleFactor = parseFloat(newScaleFactor);
    if (isNaN(newScaleFactor)) return;
    const newWidth = convertToCurrentUnit(pdfSize.width * newScaleFactor);
    const newHeight = convertToCurrentUnit(pdfSize.height * newScaleFactor);
    updateDimensions(newWidth, newHeight, newScaleFactor);
  };

  const formatDimension = (value) => {
    return `${value.toFixed(2)}${unit === "inch" ? '"' : "cm"}`;
  };

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-2xl font-semibold mb-6 text-gray-800">
        Set Completed Size
      </h2>
      <div className="flex-grow flex flex-col justify-center">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Width ({unit})
              </label>
              <input
                type="number"
                value={width}
                onChange={(e) => handleWidthChange(e.target.value)}
                step="0.01"
                className="w-full border border-gray-300 rounded-md p-2"
              />
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Height ({unit})
              </label>
              <input
                type="number"
                value={height}
                onChange={(e) => handleHeightChange(e.target.value)}
                step="0.01"
                className="w-full border border-gray-300 rounded-md p-2"
              />
            </div>

            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-700">
                Scale Factor
              </label>
              <input
                type="number"
                value={scaleFactor}
                onChange={(e) => handleScaleFactorChange(e.target.value)}
                step="0.01"
                className="w-full border border-gray-300 rounded-md p-2"
              />
            </div>
          </div>

          <div className="mt-8 p-4 bg-gray-50 rounded-md">
            <h3 className="text-lg font-semibold mb-2 text-gray-700">
              Original PDF Size
            </h3>
            <p className="text-sm text-gray-600">
              {formatDimension(convertToCurrentUnit(pdfSize.width))} x{" "}
              {formatDimension(convertToCurrentUnit(pdfSize.height))}
            </p>
          </div>

          <div className="mt-8 p-4 bg-blue-50 rounded-md">
            <h3 className="text-lg font-semibold mb-2 text-blue-700">
              Completed Size
            </h3>
            <p className="text-sm text-blue-600">
              {formatDimension(width)} x {formatDimension(height)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintSizeStep;
