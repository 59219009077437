import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { loadSettings, saveSettings } from "../utils/settings";
import splitPDF from "../utils/splitPDF";

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [step, setStep] = useState(1);
  const [file, setFile] = useState(null);
  const [originalFileName, setOriginalFileName] = useState("");
  const [pdfSize, setPdfSize] = useState({ width: 0, height: 0 });
  const [printSize, setPrintSize] = useState({ width: 0, height: 0 });
  const [scaleFactor, setScaleFactor] = useState(1);
  const [splitMethod, setSplitMethod] = useState("standardSize");
  const [fixedSize, setFixedSize] = useState({ width: 8.5, height: 11.0 });
  const [grid, setGrid] = useState([[1, 1]]);
  const [unit, setUnit] = useState("inch");
  const [bleedSize, setBleedSize] = useState(0.25);
  const [showCropMarks, setShowCropMarks] = useState(true);
  const [showPageNumbers, setShowPageNumbers] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [progress, setProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [hasDownloaded, setHasDownloaded] = useState(false);

  const cancelRef = useRef(false);

  useEffect(() => {
    const savedSettings = loadSettings();
    if (savedSettings) {
      setSplitMethod(savedSettings.splitMethod);
      setFixedSize(savedSettings.fixedSize);
      setGrid(savedSettings.grid);
      setUnit(savedSettings.unit);
      setBleedSize(savedSettings.bleedSize);
      setShowCropMarks(savedSettings.showCropMarks);
      setShowPageNumbers(savedSettings.showPageNumbers);

      if (savedSettings.printSize) {
        setPrintSize(savedSettings.printSize);
      }
      if (savedSettings.scaleFactor) {
        setScaleFactor(savedSettings.scaleFactor);
      }
    }
  }, []);

  useEffect(() => {
    saveSettings({
      splitMethod,
      fixedSize,
      grid,
      unit,
      bleedSize,
      showCropMarks,
      showPageNumbers,
      printSize,
      scaleFactor,
    });
  }, [
    splitMethod,
    fixedSize,
    grid,
    unit,
    bleedSize,
    showCropMarks,
    showPageNumbers,
    printSize,
    scaleFactor,
  ]);

  const cancelJob = useCallback(() => {
    cancelRef.current = true;
    setIsProcessing(false);
    setLoading(false);
    setProgress(0);
    setError("Job cancelled by user.");
  }, []);

  const handleDownload = async () => {
    if (!file) {
      setError("No file selected. Please upload a PDF file first.");
      return;
    }

    setLoading(true);
    setIsProcessing(true);
    setError("");
    setProgress(0);
    cancelRef.current = false;

    try {
      const blob = await splitPDF({
        file,
        splitMethod,
        fixedSize,
        grid,
        printSize,
        bleedSize,
        showCropMarks,
        showPageNumbers,
        unit,
        setProgress,
        cancelRef,
      });

      if (!cancelRef.current) {
        const numPieces =
          splitMethod === "equalGrid"
            ? grid.reduce((sum, row) => sum + row.length, 0)
            : Math.ceil(printSize.width / (fixedSize.width - 2 * bleedSize)) *
              Math.ceil(printSize.height / (fixedSize.height - 2 * bleedSize));
        const newFileName = `${originalFileName}-PDF-Gridder-${numPieces}-pages.pdf`;

        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = newFileName;
        link.click();

        setHasDownloaded(true);
      }
    } catch (error) {
      if (error.message !== "Job cancelled") {
        console.error("Error splitting PDF:", error);
        setError(`An error occurred while splitting the PDF: ${error.message}`);
      }
    } finally {
      setLoading(false);
      setIsProcessing(false);
      setProgress(0);
    }
  };

  const resetApp = useCallback(() => {
    setStep(1);
    setFile(null);
    setOriginalFileName("");
    setPdfSize({ width: 0, height: 0 });
    setPrintSize({ width: 0, height: 0 });
    setScaleFactor(1);
    setSplitMethod("standardSize");
    setFixedSize({ width: 8.5, height: 11.0 });
    setGrid([[1, 1]]);
    setBleedSize(0.25);
    setShowCropMarks(true);
    setShowPageNumbers(true);
    setLoading(false);
    setError("");
    setProgress(0);
    setIsProcessing(false);
    setTotalPages(1);
    setHasDownloaded(false);
  }, []);

  const value = {
    step,
    setStep,
    file,
    setFile,
    originalFileName,
    setOriginalFileName,
    pdfSize,
    setPdfSize,
    printSize,
    setPrintSize,
    scaleFactor,
    setScaleFactor,
    splitMethod,
    setSplitMethod,
    fixedSize,
    setFixedSize,
    grid,
    setGrid,
    unit,
    setUnit,
    bleedSize,
    setBleedSize,
    showCropMarks,
    setShowCropMarks,
    showPageNumbers,
    setShowPageNumbers,
    loading,
    setLoading,
    error,
    setError,
    progress,
    setProgress,
    isProcessing,
    setIsProcessing,
    cancelJob,
    cancelRef,
    totalPages,
    setTotalPages,
    hasDownloaded,
    setHasDownloaded,
    handleDownload,
    resetApp,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
