import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";

const DragDropUpload = ({ onFileUpload }) => {
  const [uploadedFile, setUploadedFile] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setUploadedFile(file);
        onFileUpload(file);
      }
    },
    [onFileUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "application/pdf": [".pdf"] },
    multiple: false,
  });

  return (
    <div
      {...getRootProps()}
      className={`
        border-4 border-dashed rounded-lg p-10 text-center cursor-pointer transition-all duration-300 ease-in-out
        ${
          isDragActive
            ? "border-blue-500 bg-blue-50 scale-105"
            : uploadedFile
            ? "border-green-500 bg-green-50"
            : "border-gray-300 hover:border-gray-400 hover:bg-gray-50"
        }
      `}
    >
      <input {...getInputProps()} />
      {uploadedFile ? (
        <div className="animate-fade-in">
          <svg
            className="mx-auto h-16 w-16 text-green-500 mb-4 animate-bounce"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <p className="text-lg font-medium text-green-600 mb-2">
            File uploaded: {uploadedFile.name}
          </p>
          <p className="text-sm text-green-500">
            Drop a new file or click to replace
          </p>
        </div>
      ) : (
        <div className={isDragActive ? "animate-pulse" : ""}>
          <svg
            className="mx-auto h-16 w-16 text-gray-400 mb-4"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <p className="text-lg font-medium text-gray-700 mb-2">
            {isDragActive
              ? "Drop the PDF file here"
              : "Drag & drop a PDF file here"}
          </p>
          <p className="text-sm text-gray-500">or click to select one</p>
          <p className="mt-2 text-xs text-gray-400">PDF files only</p>
        </div>
      )}
    </div>
  );
};

export default DragDropUpload;
