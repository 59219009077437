import React from "react";
import { useAppContext } from "../context/AppContext";
import { Download, HelpCircle, AlertCircle, RefreshCw } from "lucide-react";
import Tooltip from "./Tooltip";

const FinalStep = () => {
  const {
    originalFileName,
    fixedSize,
    bleedSize,
    showCropMarks,
    setShowCropMarks,
    showPageNumbers,
    setShowPageNumbers,
    unit,
    printSize,
    isProcessing,
    totalPages,
    hasDownloaded,
    setHasDownloaded,
    handleDownload,
    resetApp,
  } = useAppContext();

  const formatDimension = (value) => {
    return `${value.toFixed(2)}${unit === "inch" ? '"' : "cm"}`;
  };

  const handleDownloadClick = async () => {
    await handleDownload();
    setHasDownloaded(true);
  };

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-2xl font-semibold mb-6 text-gray-800">
        Final Configuration & Download
      </h2>
      <div className="flex-grow overflow-y-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-2 text-gray-700">
              Summary
            </h3>
            <ul className="text-sm text-gray-600 space-y-1">
              <li>
                <strong>File:</strong> {originalFileName}
              </li>
              <li>
                <strong>Print size:</strong> {formatDimension(printSize.width)}{" "}
                x {formatDimension(printSize.height)}
              </li>
              <li>
                <strong>Paper size:</strong> {formatDimension(fixedSize.width)}{" "}
                x {formatDimension(fixedSize.height)}
              </li>
              <li>
                <strong>Bleed size:</strong> {formatDimension(bleedSize)}
              </li>
              <li>
                <strong>Total pages:</strong> {totalPages}
              </li>
            </ul>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-2 text-gray-700">
              Options
            </h3>
            <div className="space-y-2">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={showCropMarks}
                  onChange={(e) => setShowCropMarks(e.target.checked)}
                  className="form-checkbox text-blue-600"
                />
                <span>Show Crop Marks</span>
                <Tooltip content="Crop marks help with aligning and cutting printed pages">
                  <HelpCircle size={16} className="text-gray-400" />
                </Tooltip>
              </label>
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={showPageNumbers}
                  onChange={(e) => setShowPageNumbers(e.target.checked)}
                  className="form-checkbox text-blue-600"
                />
                <span>Show Page Numbers / Coordinates</span>
                <Tooltip content="Page numbers help with assembling the final print in the correct order">
                  <HelpCircle size={16} className="text-gray-400" />
                </Tooltip>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 space-y-4">
        <div className="flex flex-col md:flex-row md:justify-between md:items-center space-y-4 md:space-y-0 md:space-x-4">
          <button
            onClick={handleDownloadClick}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg transition duration-300 ease-in-out flex items-center justify-center space-x-2 flex-grow"
            disabled={isProcessing}
          >
            {isProcessing ? (
              <>
                <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></span>
                <span>Processing...</span>
              </>
            ) : (
              <>
                <Download size={20} />
                <span>Download Split PDF</span>
              </>
            )}
          </button>

          {hasDownloaded && (
            <button
              onClick={resetApp}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-3 px-6 rounded-lg transition duration-300 ease-in-out flex items-center justify-center space-x-2 flex-grow"
            >
              <RefreshCw size={20} />
              <span>Process Another PDF</span>
            </button>
          )}
        </div>

        {hasDownloaded && (
          <div className="bg-blue-50 p-4 rounded-lg mt-4">
            <p className="text-sm text-blue-700">
              <AlertCircle size={20} className="inline mr-2" />
              Your PDF has been split successfully. You can now process another
              PDF or close this page.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FinalStep;
