import { PDFDocument, rgb } from "pdf-lib";

const splitPDF = async ({
  file,
  splitMethod,
  fixedSize,
  grid,
  printSize,
  bleedSize,
  showCropMarks,
  showPageNumbers,
  unit,
  setProgress,
  cancelRef,
}) => {
  const arrayBuffer = await file.arrayBuffer();
  const pdfDoc = await PDFDocument.load(arrayBuffer);
  const page = pdfDoc.getPages()[0];
  const { width: originalWidth, height: originalHeight } = page.getSize();

  const pointsPerUnit = unit === "inch" ? 72 : 28.35;
  const printSizeInPoints = {
    width: printSize.width * pointsPerUnit,
    height: printSize.height * pointsPerUnit,
  };

  const scaleX = printSizeInPoints.width / originalWidth;
  const scaleY = printSizeInPoints.height / originalHeight;
  const scale = Math.min(scaleX, scaleY);

  const scaledWidth = originalWidth * scale;
  const scaledHeight = originalHeight * scale;

  const outputPdf = await PDFDocument.create();
  const bleedPt = bleedSize * pointsPerUnit;

  let pieces = [];
  if (splitMethod === "standardSize" || splitMethod === "fixedSize") {
    const contentWidth = (fixedSize.width - 2 * bleedSize) * pointsPerUnit;
    const contentHeight = (fixedSize.height - 2 * bleedSize) * pointsPerUnit;
    const piecesX = Math.ceil(scaledWidth / contentWidth);
    const piecesY = Math.ceil(scaledHeight / contentHeight);
    for (let y = 0; y < piecesY; y++) {
      for (let x = 0; x < piecesX; x++) {
        pieces.push({ x, y });
      }
    }
  } else if (splitMethod === "equalGrid") {
    for (let y = 0; y < grid.length; y++) {
      for (let x = 0; x < grid[y].length; x++) {
        pieces.push({ x, y });
      }
    }
  } else {
    throw new Error("Invalid split method");
  }

  const totalPieces = pieces.length;

  const embeddedPage = await outputPdf.embedPage(page);

  for (let i = 0; i < pieces.length; i++) {
    if (cancelRef.current) {
      throw new Error("Job cancelled");
    }

    const { x, y } = pieces[i];

    let pieceWidth, pieceHeight, xOffset, yOffset, contentWidth, contentHeight;
    if (splitMethod === "standardSize" || splitMethod === "fixedSize") {
      contentWidth = (fixedSize.width - 2 * bleedSize) * pointsPerUnit;
      contentHeight = (fixedSize.height - 2 * bleedSize) * pointsPerUnit;
      pieceWidth = fixedSize.width * pointsPerUnit;
      pieceHeight = fixedSize.height * pointsPerUnit;
      xOffset = x * contentWidth;
      yOffset = scaledHeight - (y + 1) * contentHeight;
    } else if (splitMethod === "equalGrid") {
      const totalGridWidth = grid[0].reduce((sum, cell) => sum + cell, 0);
      const cellWidth = scaledWidth / totalGridWidth;
      const cellHeight = scaledHeight / grid.length;
      contentWidth = cellWidth * grid[y][x];
      contentHeight = cellHeight;
      pieceWidth = contentWidth + 2 * bleedPt;
      pieceHeight = contentHeight + 2 * bleedPt;
      xOffset = grid[y]
        .slice(0, x)
        .reduce((sum, cell) => sum + cell * cellWidth, 0);
      yOffset = scaledHeight - (y + 1) * cellHeight;
    }

    const newPage = outputPdf.addPage([pieceWidth, pieceHeight]);

    // Draw the content
    newPage.drawPage(embeddedPage, {
      x: bleedPt - xOffset,
      y: bleedPt - yOffset,
      width: scaledWidth,
      height: scaledHeight,
    });

    // Fill bleed area with white
    newPage.drawRectangle({
      x: 0,
      y: 0,
      width: newPage.getWidth(),
      height: bleedPt,
      color: rgb(1, 1, 1),
    });
    newPage.drawRectangle({
      x: 0,
      y: newPage.getHeight() - bleedPt,
      width: newPage.getWidth(),
      height: bleedPt,
      color: rgb(1, 1, 1),
    });
    newPage.drawRectangle({
      x: 0,
      y: 0,
      width: bleedPt,
      height: newPage.getHeight(),
      color: rgb(1, 1, 1),
    });
    newPage.drawRectangle({
      x: newPage.getWidth() - bleedPt,
      y: 0,
      width: bleedPt,
      height: newPage.getHeight(),
      color: rgb(1, 1, 1),
    });

    if (showCropMarks) {
      const drawLine = (x1, y1, x2, y2) => {
        newPage.drawLine({
          start: { x: x1, y: y1 },
          end: { x: x2, y: y2 },
          thickness: 0.5,
          color: rgb(0, 0, 0),
        });
      };

      // Top-left
      drawLine(0, bleedPt, bleedPt, bleedPt);
      drawLine(bleedPt, 0, bleedPt, bleedPt);
      // Top-right
      drawLine(pieceWidth, bleedPt, pieceWidth - bleedPt, bleedPt);
      drawLine(pieceWidth - bleedPt, 0, pieceWidth - bleedPt, bleedPt);
      // Bottom-left
      drawLine(0, pieceHeight - bleedPt, bleedPt, pieceHeight - bleedPt);
      drawLine(bleedPt, pieceHeight, bleedPt, pieceHeight - bleedPt);
      // Bottom-right
      drawLine(
        pieceWidth,
        pieceHeight - bleedPt,
        pieceWidth - bleedPt,
        pieceHeight - bleedPt
      );
      drawLine(
        pieceWidth - bleedPt,
        pieceHeight,
        pieceWidth - bleedPt,
        pieceHeight - bleedPt
      );
    }

    if (showPageNumbers && bleedPt >= 18) {
      const fontSize = 10;
      newPage.drawText(`(${x + 1}, ${y + 1})`, {
        x: bleedPt + 5,
        y: pieceHeight - bleedPt + fontSize - 5,
        size: fontSize,
        color: rgb(0.5, 0.5, 0.5),
      });
    }

    setProgress(((i + 1) / totalPieces) * 100);
  }

  const pdfBytes = await outputPdf.save({ useObjectStreams: false });
  return new Blob([pdfBytes], { type: "application/pdf" });
};

export default splitPDF;
