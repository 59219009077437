import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useAppContext } from "../context/AppContext";

const PaperSizeStep = () => {
  const {
    printSize,
    unit,
    bleedSize,
    setBleedSize,
    fixedSize,
    setFixedSize,
    setTotalPages,
  } = useAppContext();

  const [paperWidth, setPaperWidth] = useState(fixedSize.width);
  const [paperHeight, setPaperHeight] = useState(fixedSize.height);
  const [printAreaWidth, setPrintAreaWidth] = useState(
    fixedSize.width - 2 * bleedSize
  );
  const [printAreaHeight, setPrintAreaHeight] = useState(
    fixedSize.height - 2 * bleedSize
  );
  const [numColumns, setNumColumns] = useState(
    printSize.width / (fixedSize.width - 2 * bleedSize)
  );
  const [numRows, setNumRows] = useState(
    printSize.height / (fixedSize.height - 2 * bleedSize)
  );
  const [selectedPaperSize, setSelectedPaperSize] = useState("");

  const roundToTwoDecimals = (num) => Number(num.toFixed(2));

  const inchToCm = (inch) => inch * 2.54;
  const cmToInch = (cm) => cm / 2.54;

  const convertToCurrentUnit = (valueInInches) => {
    return unit === "cm" ? inchToCm(valueInInches) : valueInInches;
  };

  const convertToInches = (value) => {
    return unit === "cm" ? cmToInch(value) : value;
  };

  const paperSizes = useMemo(
    () => ({
      Letter: { width: 8.5, height: 11 },
      Legal: { width: 8.5, height: 14 },
      Tabloid: { width: 11, height: 17 },
      A4: { width: 8.27, height: 11.69 },
      A3: { width: 11.69, height: 16.54 },
      A5: { width: 5.83, height: 8.27 },
      A2: { width: 16.54, height: 23.39 },
      B5: { width: 6.93, height: 9.84 },
    }),
    []
  );

  const updateAllFields = useCallback(
    ({
      paperWidth,
      paperHeight,
      printAreaWidth,
      printAreaHeight,
      numColumns,
      numRows,
      newBleedSize,
    }) => {
      const updatedBleedSize =
        newBleedSize !== undefined ? newBleedSize : bleedSize;

      if (paperWidth !== undefined && paperHeight !== undefined) {
        setPaperWidth(paperWidth);
        setPaperHeight(paperHeight);
        const newPrintAreaWidth = paperWidth - 2 * updatedBleedSize;
        const newPrintAreaHeight = paperHeight - 2 * updatedBleedSize;
        setPrintAreaWidth(newPrintAreaWidth);
        setPrintAreaHeight(newPrintAreaHeight);
        setNumColumns(printSize.width / newPrintAreaWidth);
        setNumRows(printSize.height / newPrintAreaHeight);
        setFixedSize({ width: paperWidth, height: paperHeight });
      } else if (
        printAreaWidth !== undefined &&
        printAreaHeight !== undefined
      ) {
        setPrintAreaWidth(printAreaWidth);
        setPrintAreaHeight(printAreaHeight);
        const newPaperWidth = printAreaWidth + 2 * updatedBleedSize;
        const newPaperHeight = printAreaHeight + 2 * updatedBleedSize;
        setPaperWidth(newPaperWidth);
        setPaperHeight(newPaperHeight);
        setNumColumns(printSize.width / printAreaWidth);
        setNumRows(printSize.height / printAreaHeight);
        setFixedSize({ width: newPaperWidth, height: newPaperHeight });
      } else if (numColumns !== undefined && numRows !== undefined) {
        setNumColumns(numColumns);
        setNumRows(numRows);
        const newPrintAreaWidth = printSize.width / numColumns;
        const newPrintAreaHeight = printSize.height / numRows;
        setPrintAreaWidth(newPrintAreaWidth);
        setPrintAreaHeight(newPrintAreaHeight);
        const newPaperWidth = newPrintAreaWidth + 2 * updatedBleedSize;
        const newPaperHeight = newPrintAreaHeight + 2 * updatedBleedSize;
        setPaperWidth(newPaperWidth);
        setPaperHeight(newPaperHeight);
        setFixedSize({ width: newPaperWidth, height: newPaperHeight });
      }

      if (newBleedSize !== undefined) {
        setBleedSize(newBleedSize);
      }

      const updatedNumColumns =
        numColumns !== undefined
          ? numColumns
          : printSize.width / (paperWidth - 2 * updatedBleedSize);
      const updatedNumRows =
        numRows !== undefined
          ? numRows
          : printSize.height / (paperHeight - 2 * updatedBleedSize);
      const totalPages =
        Math.ceil(updatedNumColumns) * Math.ceil(updatedNumRows);
      setTotalPages(totalPages);
    },
    [bleedSize, printSize, setBleedSize, setFixedSize, setTotalPages]
  );

  useEffect(() => {
    updateAllFields({
      paperWidth: fixedSize.width,
      paperHeight: fixedSize.height,
      bleedSize,
    });
  }, [fixedSize, bleedSize, printSize, updateAllFields]);

  const handlePaperSizeChange = (size) => {
    setSelectedPaperSize(size);
    const selectedSize = paperSizes[size];
    if (selectedSize) {
      updateAllFields({
        paperWidth: selectedSize.width,
        paperHeight: selectedSize.height,
      });
    }
  };

  const handleBleedSizeChange = (newBleedSize) => {
    newBleedSize = convertToInches(parseFloat(newBleedSize));
    updateAllFields({
      paperWidth,
      paperHeight,
      newBleedSize,
    });
  };

  const handleInputChange = (field, value) => {
    value = parseFloat(value);
    if (isNaN(value)) return;

    const valueInInches = convertToInches(value);

    switch (field) {
      case "paperWidth":
      case "paperHeight":
        updateAllFields({
          [field]: valueInInches,
          [field === "paperWidth" ? "paperHeight" : "paperWidth"]:
            field === "paperWidth" ? paperHeight : paperWidth,
        });
        break;
      case "printAreaWidth":
      case "printAreaHeight":
        updateAllFields({
          [field]: valueInInches,
          [field === "printAreaWidth" ? "printAreaHeight" : "printAreaWidth"]:
            field === "printAreaWidth" ? printAreaHeight : printAreaWidth,
        });
        break;
      case "numColumns":
      case "numRows":
        updateAllFields({
          [field]: value,
          [field === "numColumns" ? "numRows" : "numColumns"]:
            field === "numColumns" ? numRows : numColumns,
        });
        break;
      default:
        console.warn(`Unhandled field: ${field}`);
    }
  };

  const totalSheets = Math.ceil(numColumns) * Math.ceil(numRows);

  const formatPaperSize = (size) => {
    const { width, height } = size;
    const formattedWidth = convertToCurrentUnit(width);
    const formattedHeight = convertToCurrentUnit(height);
    return `${formattedWidth.toFixed(2)} x ${formattedHeight.toFixed(
      2
    )} ${unit}`;
  };

  const displayValue = (value) =>
    roundToTwoDecimals(convertToCurrentUnit(value));

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-2xl font-semibold mb-6 text-gray-800">
        Choose Paper Size
      </h2>
      <div className="flex-grow overflow-y-auto">
        <div className="space-y-6">
          <div className="flex space-x-4">
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Paper Width
              </label>
              <input
                type="number"
                value={displayValue(paperWidth)}
                onChange={(e) =>
                  handleInputChange("paperWidth", e.target.value)
                }
                className="w-full border border-gray-300 rounded-md p-2"
                step="0.01"
              />
            </div>
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Paper Height
              </label>
              <input
                type="number"
                value={displayValue(paperHeight)}
                onChange={(e) =>
                  handleInputChange("paperHeight", e.target.value)
                }
                className="w-full border border-gray-300 rounded-md p-2"
                step="0.01"
              />
            </div>
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Bleed Size
              </label>
              <input
                type="number"
                value={displayValue(bleedSize)}
                onChange={(e) => handleBleedSizeChange(e.target.value)}
                className="w-full border border-gray-300 rounded-md p-2"
                step="0.01"
              />
            </div>
          </div>

          <div className="flex space-x-4">
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Print Area Width
              </label>
              <input
                type="number"
                value={displayValue(printAreaWidth)}
                onChange={(e) =>
                  handleInputChange("printAreaWidth", e.target.value)
                }
                className="w-full border border-gray-300 rounded-md p-2"
                step="0.01"
              />
            </div>
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Print Area Height
              </label>
              <input
                type="number"
                value={displayValue(printAreaHeight)}
                onChange={(e) =>
                  handleInputChange("printAreaHeight", e.target.value)
                }
                className="w-full border border-gray-300 rounded-md p-2"
                step="0.01"
              />
            </div>
            <div className="flex-1"></div>
          </div>

          <div className="flex space-x-4">
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Number of Columns
              </label>
              <input
                type="number"
                value={roundToTwoDecimals(numColumns)}
                onChange={(e) =>
                  handleInputChange("numColumns", e.target.value)
                }
                className="w-full border border-gray-300 rounded-md p-2"
                step="0.01"
              />
            </div>
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Number of Rows
              </label>
              <input
                type="number"
                value={roundToTwoDecimals(numRows)}
                onChange={(e) => handleInputChange("numRows", e.target.value)}
                className="w-full border border-gray-300 rounded-md p-2"
                step="0.01"
              />
            </div>
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Total Sheets
              </label>
              <input
                type="text"
                value={`${totalSheets} (${Math.ceil(numColumns)}x${Math.ceil(
                  numRows
                )})`}
                readOnly
                className="w-full border border-gray-300 rounded-md p-2 bg-gray-100"
              />
            </div>
          </div>
        </div>

        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Common Paper Sizes
          </label>
          <select
            value={selectedPaperSize}
            onChange={(e) => handlePaperSizeChange(e.target.value)}
            className="w-full border border-gray-300 rounded-md p-2"
          >
            <option value="">Select a paper size</option>
            {Object.entries(paperSizes).map(([size, dimensions]) => (
              <option key={size} value={size}>
                {size} ({formatPaperSize(dimensions)})
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default PaperSizeStep;
