import React, { useEffect, useRef, useState } from "react";
import { useAppContext } from "../context/AppContext";
import { ChevronLeft, ChevronRight, CheckCircle, Download } from "lucide-react";

const VerticalNavBar = ({ steps, getStepSummary, handleDownload }) => {
  const { step, setStep } = useAppContext();
  const stepsContainerRef = useRef(null);
  const stepsRef = useRef([]);
  const [containerWidth, setContainerWidth] = useState(0);

  const currentStep = steps[step - 1];
  const progress = (step / steps.length) * 100;

  useEffect(() => {
    const updateContainerWidth = () => {
      if (stepsContainerRef.current) {
        setContainerWidth(stepsContainerRef.current.offsetWidth);
      }
    };

    updateContainerWidth();
    window.addEventListener("resize", updateContainerWidth);

    return () => window.removeEventListener("resize", updateContainerWidth);
  }, []);

  useEffect(() => {
    if (stepsContainerRef.current && stepsRef.current[step - 1]) {
      const container = stepsContainerRef.current;
      const currentStepElement = stepsRef.current[step - 1];
      const containerCenter = container.offsetWidth / 2;
      const currentStepCenter =
        currentStepElement.offsetLeft + currentStepElement.offsetWidth / 2;
      const offset = containerCenter - currentStepCenter;

      container.style.transform = `translateX(${offset}px)`;
    }
  }, [step, containerWidth]);

  const truncateFileName = (summary) => {
    const match = summary.match(/^(.+?)(\s*\.pdf)$/i);
    if (match) {
      let [, name, extension] = match;
      if (name.length > 20) {
        name = name.substring(0, 20).trim();
        return `${name}...${extension.trim()}`;
      }
    }
    return summary;
  };

  return (
    <div className="bg-white shadow-lg">
      <div className="h-1 bg-gray-200">
        <div
          className="h-full bg-blue-500 transition-all duration-300 ease-in-out"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <div className="flex justify-between items-center px-4 py-2 md:py-4">
        <button
          onClick={() => step > 1 && setStep(step - 1)}
          className={`flex items-center px-4 py-2 rounded-md transition-colors duration-300 ${
            step === 1 ? "invisible" : "bg-gray-200 hover:bg-gray-300"
          }`}
        >
          <ChevronLeft className="mr-1" size={20} />
          <span className="text-sm md:text-base">Previous</span>
        </button>
        <div className="flex-grow overflow-hidden relative">
          <div className="absolute inset-y-0 left-0 w-16 bg-gradient-to-r from-white to-transparent z-10"></div>
          <div className="absolute inset-y-0 right-0 w-16 bg-gradient-to-l from-white to-transparent z-10"></div>
          <div
            className="hidden md:flex items-start justify-start transition-all duration-300 ease-in-out px-16"
            ref={stepsContainerRef}
          >
            {steps.map((s, index) => (
              <div
                key={index}
                ref={(el) => (stepsRef.current[index] = el)}
                className={`cursor-pointer transition-all duration-300 ease-in-out px-4 py-2 rounded whitespace-nowrap flex-shrink-0 ${
                  index + 1 === step ? "mx-4" : "mx-1"
                }`}
                onClick={() => setStep(index + 1)}
              >
                <div
                  className={`flex items-center justify-center ${
                    index + 1 === step
                      ? "font-semibold text-blue-600 bg-blue-100 px-2 py-1 rounded"
                      : index + 1 < step
                      ? "text-gray-700"
                      : "text-gray-500"
                  } ${index + 1 > step ? "opacity-50" : ""}`}
                >
                  {index + 1 < step && (
                    <CheckCircle size={20} className="text-green-500 mr-2" />
                  )}
                  <span className="md:text-base">{s.title}</span>
                </div>
                <div className="text-xs text-gray-600 mt-1 h-4 text-center">
                  {index + 1 < step
                    ? truncateFileName(getStepSummary(index + 1))
                    : ""}
                </div>
              </div>
            ))}
          </div>
          <div className="md:hidden text-center">
            <div className="font-semibold">{currentStep.title}</div>
            <div className="text-xs text-gray-500">
              Step {step} of {steps.length}
            </div>
          </div>
        </div>
        {step === steps.length ? (
          <button
            onClick={handleDownload}
            className="flex items-center px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md transition-colors duration-300"
          >
            <Download className="mr-1" size={20} />
            <span className="text-sm md:text-base">Download</span>
          </button>
        ) : (
          <button
            onClick={() => step < steps.length && setStep(step + 1)}
            className={`flex items-center px-4 py-2 rounded-md transition-colors duration-300 ${
              step === steps.length
                ? "invisible"
                : "bg-gray-200 hover:bg-gray-300"
            }`}
          >
            <span className="text-sm md:text-base">
              {step === steps.length - 1 ? "Finish" : "Next"}
            </span>
            <ChevronRight className="ml-1" size={20} />
          </button>
        )}
      </div>
    </div>
  );
};

export default VerticalNavBar;
