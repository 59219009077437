const SETTINGS_KEY = "pdfSplitterSettings";

export const saveSettings = (settings) => {
  localStorage.setItem(SETTINGS_KEY, JSON.stringify(settings));
};

export const loadSettings = () => {
  const savedSettings = localStorage.getItem(SETTINGS_KEY);
  return savedSettings ? JSON.parse(savedSettings) : null;
};
