import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const AboutPage = () => {
  return (
    <div className="container mx-auto px-4 py-8 max-w-3xl">
      <Helmet>
        <title>
          About PDF Gridder - Transform Large PDFs into Printable Pages
        </title>
        <meta
          name="description"
          content="Learn about PDF Gridder, the free online tool for splitting large PDFs into printable pieces. Ideal for posters, banners, and oversized prints using standard printers."
        />
      </Helmet>

      <header className="mb-8 text-center">
        <Link to="/" className="text-blue-600 hover:underline">
          &larr; Back to PDF Gridder
        </Link>
        <h1 className="text-4xl font-bold mt-4 mb-2">About PDF Gridder</h1>
        <p className="text-xl text-gray-600">
          Simplifying large-format printing for everyone
        </p>
      </header>

      <section className="mb-10">
        <h2 className="text-3xl font-semibold mb-4">What is PDF Gridder?</h2>
        <p className="mb-4 text-lg">
          PDF Gridder is a free online tool designed to help you print large PDF
          files using standard printers. It works by splitting oversized PDFs
          into a grid of standard-sized pages that you can easily print and
          assemble.
        </p>
        <p className="mb-4 text-lg">
          Whether you're creating posters, banners, educational materials, or
          any other large-format prints, PDF Gridder simplifies the process,
          allowing you to achieve professional results without specialized
          equipment.
        </p>
      </section>

      <section className="mb-10">
        <h2 className="text-3xl font-semibold mb-4">How It Works</h2>
        <ol className="list-decimal list-inside space-y-3 text-lg">
          <li>
            <strong>Upload your PDF:</strong> Start by uploading the
            large-format PDF you want to print.
          </li>
          <li>
            <strong>Set your dimensions:</strong> Specify the final print size
            you're aiming for.
          </li>
          <li>
            <strong>Choose your settings:</strong> Select your paper size and
            adjust other print settings as needed.
          </li>
          <li>
            <strong>Download and print:</strong> Get your split PDF, ready for
            printing on standard paper.
          </li>
        </ol>
      </section>

      <section className="mb-10">
        <h2 className="text-3xl font-semibold mb-4">Key Features</h2>
        <ul className="list-disc list-inside space-y-2 text-lg">
          <li>User-friendly interface</li>
          <li>Support for various paper sizes</li>
          <li>Customizable bleed settings</li>
          <li>Optional crop marks and page numbers</li>
          <li>Grid layout preview</li>
          <li>Free to use, no registration required</li>
        </ul>
      </section>

      <section className="mb-10">
        <h2 className="text-3xl font-semibold mb-4">
          Post-Printing Assembly Guide
        </h2>
        <p className="mb-4 text-lg">
          Once you've printed your PDF pieces, follow these steps to assemble
          your large-format print:
        </p>
        <ol className="list-decimal list-inside space-y-4 text-lg">
          <li>
            <strong>Trimming:</strong>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-1">
              <li>Use a sharp paper cutter or scissors for precise cuts</li>
              <li>If enabled, use crop marks as cutting guides</li>
              <li>
                For bleed areas, trim slightly inside the printed area for full
                coverage
              </li>
            </ul>
          </li>
          <li>
            <strong>Alignment:</strong>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-1">
              <li>
                Use page numbers or coordinates to arrange pieces in order
              </li>
              <li>
                Lay out all pieces before adhering to ensure correct positioning
              </li>
              <li>Use a large, flat surface for assembly</li>
            </ul>
          </li>
          <li>
            <strong>Joining:</strong>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-1">
              <li>Use clear tape on the back for temporary joining</li>
              <li>
                For a permanent solution, use a glue stick or spray adhesive
              </li>
              <li>
                Consider overlapping edges slightly for a seamless appearance
              </li>
            </ul>
          </li>
          <li>
            <strong>Finishing:</strong>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-1">
              <li>Consider laminating or framing your print for durability</li>
              <li>For outdoor use, look into weather-resistant options</li>
            </ul>
          </li>
        </ol>
      </section>

      <section className="mb-10">
        <h2 className="text-3xl font-semibold mb-4">
          Frequently Asked Questions
        </h2>
        <div className="space-y-6">
          <div>
            <h3 className="text-xl font-semibold">
              Is PDF Gridder free to use?
            </h3>
            <p className="text-lg">
              Yes, PDF Gridder is completely free to use. There are no hidden
              costs or subscriptions.
            </p>
          </div>
          <div>
            <h3 className="text-xl font-semibold">
              Do I need to create an account?
            </h3>
            <p className="text-lg">
              No, you can use PDF Gridder without creating an account or
              providing any personal information.
            </p>
          </div>
          <div>
            <h3 className="text-xl font-semibold">Is my PDF file secure?</h3>
            <p className="text-lg">
              Your privacy and data security are important to us. Files are
              processed in your browser and are not uploaded to or stored on our
              servers.
            </p>
          </div>
          <div>
            <h3 className="text-xl font-semibold">
              Can I use PDF Gridder on my mobile device?
            </h3>
            <p className="text-lg">
              While PDF Gridder's interface may be accessible on mobile devices,
              we recommend using it on a desktop or laptop computer for the best
              experience and full functionality. Mobile browsers may have
              limited resources for processing complex PDFs.
            </p>
          </div>
          <div>
            <h3 className="text-xl font-semibold">
              What types of projects is PDF Gridder best suited for?
            </h3>
            <p className="text-lg">
              PDF Gridder is ideal for a variety of large-format printing
              projects, including:
            </p>
            <ul className="list-disc list-inside ml-6 mt-2 space-y-1 text-lg">
              <li>Posters and banners for events or advertising</li>
              <li>Educational materials like large charts or maps</li>
              <li>Architectural plans or engineering drawings</li>
              <li>Art prints or photo enlargements</li>
              <li>DIY wallpaper or large wall decals</li>
              <li>Board games or large-format game materials</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="mb-10 bg-blue-50 p-6 rounded-lg">
        <h2 className="text-3xl font-semibold mb-4">Ready to Get Started?</h2>
        <p className="text-lg mb-4">
          Transform your large PDFs into printable pages and bring your ideas to
          life with PDF Gridder.
        </p>
        <Link
          to="/"
          className="inline-block bg-blue-500 text-white font-bold py-3 px-6 rounded-lg hover:bg-blue-600 transition duration-300"
        >
          Try PDF Gridder Now
        </Link>
      </section>

      <footer className="text-center text-gray-600 mt-8">
        <p className="text-lg">
          PDF Gridder logo designed by{" "}
          <a
            href="https://www.travisavery.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:underline"
          >
            Travis Avery
          </a>
        </p>
      </footer>
    </div>
  );
};

export default AboutPage;
